export const TEXT_OF = {
  ICON_ACTION: "icon_action",
};

export const DEFAULT_HEADER_FOOTER = {
  HEADER: {id: 0, name: 'No Header'},
  FOOTER: {id: 0, name: 'No Footer'},
  HEADER_FILTER: {code: 'header', status: 'active'},
  FOOTER_FILTER: {code: 'footer', status: 'active'},
};

